import {
  Component,
  ComponentRef,
  Inject,
  Injectable,
  Optional,
  TemplateRef,
} from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ToastContainerComponent } from '../components/toast-container.component';
import {
  InterfaceMessageData,
  InterfaceToastItemDefaultConfig,
  InterfaceToastResult,
  ToastItemConfig,
} from '../toaster.model';
import { ToasterConfigToken } from '../toaster.tokens';

export const RESETDATA: InterfaceMessageData = {
  actions: [],
};

@Injectable({ providedIn: 'root' })
export class ToasterService {
  private _overlayRef: OverlayRef;
  private _componentRef: ComponentRef<ToastContainerComponent>;
  private _initialized = false;

  private _defaultConfig: InterfaceToastItemDefaultConfig = {
    panelClass: '',
    duration: 10000,
    close: false,
  };

  constructor(
    private _overlay: Overlay,
    @Optional()
    @Inject(ToasterConfigToken)
    private _customDefaultConfig: InterfaceToastItemDefaultConfig
  ) {
    if (this._customDefaultConfig) {
      this._defaultConfig = {
        ...this._defaultConfig,
        ...this._customDefaultConfig,
      };
    }
  }

  private initialize() {
    this._overlayRef = this._overlay.create();
    const toastContainer = new ComponentPortal(ToastContainerComponent);
    this._componentRef = this._overlayRef.attach(toastContainer);
  }

  info(message: string, customSetting?: ToastItemConfig): InterfaceToastResult {
    return this._show({
      ...RESETDATA,
      ...this._defaultConfig,
      ...customSetting,
      ...{
        message,
        type: 'info',
      },
    });
  }

  success(
    message: string,
    customSetting?: ToastItemConfig
  ): InterfaceToastResult {
    return this._show({
      ...RESETDATA,
      ...this._defaultConfig,
      ...customSetting,
      ...{
        message,
        type: 'success',
      },
    });
  }

  warn(message: string, customSetting?: ToastItemConfig): InterfaceToastResult {
    return this._show({
      ...RESETDATA,
      ...this._defaultConfig,
      ...customSetting,
      ...{
        message,
        type: 'warn',
      },
    });
  }

  error(
    message: string,
    customSetting?: ToastItemConfig
  ): InterfaceToastResult {
    return this._show({
      ...RESETDATA,
      ...this._defaultConfig,
      ...customSetting,
      ...{
        message,
        type: 'error',
      },
      // Do not allow auto toastClose
      ...{
        duration: 0,
        close: true,
      },
    });
  }

  removeAll() {
    this._componentRef?.instance.removeAllItems();
  }

  private _show(data: ToastItemConfig): InterfaceToastResult {
    if (!this._initialized) {
      this.initialize();
      this._initialized = true;
    }
    const afterCloseSubject = new AsyncSubject<string>();

    const addIndex = this._componentRef.instance.addItem({
      ...data,
      key: data.key ?? JSON.stringify(data),
      afterCloseSubject,
    });

    if (typeof data.executeFunctionOnToast === 'function') {
      data.executeFunctionOnToast(data);
    }

    return {
      afterClosed: () => afterCloseSubject.asObservable(),
      close: () => this._componentRef.instance.removeItem(addIndex),
    };
  }
}
