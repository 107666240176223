import { NgModel } from '@angular/forms';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromOrderform from './orderform.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OrderformEffects } from './orderform.effects';
import { initStateFromEmbeddedData } from './init-state-from-embedded-data.reducer';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromOrderform.ORDERFORM_FEATURE_KEY,
      fromOrderform.reducer,
      {
        metaReducers: [initStateFromEmbeddedData],
      }
    ),
    EffectsModule.forFeature([OrderformEffects]),
    HttpClientModule,
  ],
})
export class OrderformModuleNgrx {}
