import { ComponentRef, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FloatingHelpButtonComponent } from './floating-help-button/floating-help-button.component';
import { ELEVIO_LANGUAGE_MAP } from './elevio.model';

@Injectable()
export class ElevioService {
  private _overlayRef: OverlayRef;
  private _componentRef: ComponentRef<FloatingHelpButtonComponent>;
  private _loaded = false;

  private initData: {
    elevioAccountId: string;
    language: string;
    brandName: string;
    elevioUser: { [key: string]: any };
  } = null;

  constructor(private _overlay: Overlay) {}

  public initialize(
    elevioAccountId: string,
    language: string,
    brandName: string,
    elevioUser: { [key: string]: any }
  ) {
    this.initData = {
      elevioAccountId,
      language: ELEVIO_LANGUAGE_MAP[language] ?? 'en',
      brandName,
      elevioUser,
    };
    this._createFloatingButton();
  }

  private _createFloatingButton() {
    this._overlayRef = this._overlay.create();
    this._overlayRef.addPanelClass(['z-[10001]', 'hidden', 'md:block']);
    const button = new ComponentPortal(FloatingHelpButtonComponent);
    this._componentRef = this._overlayRef.attach(button);
    this._componentRef.instance.execute.pipe(take(1)).subscribe(async () => {
      await this._loadElevioAndRemoveFloatingButton();
    });
  }

  private async _loadElevioIfNotLoaded() {
    if (this.initData) {
      if (!this._loaded) {
        await this._loadElevioAndRemoveFloatingButton();
      }
    } else {
      throw new Error(
        'Elevio Service was not initialized(), please initialize before!'
      );
    }
  }

  public async open() {
    await this._loadElevioIfNotLoaded();
    (window as any)._elev.open();
  }

  public async openArticle(articleNo: number) {
    await this._loadElevioIfNotLoaded();
    (window as any)._elev.openArticle(articleNo);
  }

  private async _loadElevioAndRemoveFloatingButton() {
    const { language, brandName, elevioUser } = this.initData;
    await this._loadElevioWidget(language, brandName, elevioUser);
    this._overlayRef.dispose();
    this._loaded = true;
  }

  private async _loadElevioWidget(
    language: string,
    brandName: string,
    elevioUser: { [key: string]: any }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      /* eslint-disable */
      (function (e, l, v, i, o, n) {
        e[i] || (e[i] = {}), (e[i].account_id = n);
        var g, h;
        (g = l.createElement(v)),
          (g.type = 'text/javascript'),
          (g.async = 1),
          (g.src = o + n),
          (h = l.getElementsByTagName(v)[0]),
          l.body.appendChild(g);
        e[i].q = [];
        e[i].on = function (z, y) {
          e[i].q.push([z, y]);
        };
      })(
        window,
        document,
        'script',
        '_elev',
        'https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=',
        this.initData.elevioAccountId
      );
      /* eslint-enable */
      (window as any)._elev.on('load', function (_elev) {
        _elev.setLanguage(language);
        _elev.setUser(elevioUser);
        _elev.open();
        resolve();
      });

      const replaceBrandNameExpression = new RegExp('Digistore24', 'g');

      (window as any)._elev.on('article:data:loaded', (data) => {
        (window as any)._elev.updateArticle(
          data.articleId,
          data.title.replace(replaceBrandNameExpression, brandName),
          data.body.replace(replaceBrandNameExpression, brandName)
        );
      });
    });
  }
}
