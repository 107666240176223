export const isPageBuilderDesignMode = (): boolean => {
  const isIframe = window !== window.parent;
  return !!(window as any).config && isIframe;
};

export const isPreviewMode = (): boolean => {
  return window.location.search.indexOf('preview=1') > -1;
};

export const isPageBuilderElementVisible = (el: HTMLElement) => {
  /**
   * Attenation: a page builder update could break this behavior!
   */
  const isDesktopOnlyVisible = el.closest('.pgb-desktop-only');
  const isMobileOnlyVisible = el.closest('.pgb-mobile-only');
  return (
    (isDesktopOnlyVisible !== null &&
      el.closest('body.desktop-view') !== null) ||
    (isMobileOnlyVisible !== null && el.closest('body.mobile-view') !== null) ||
    (isDesktopOnlyVisible === null && isMobileOnlyVisible === null)
  );
};
