<div class="toast-item" [ngClass]="'toast-item-' + toastItem.type">
  <div class="flex">
    <div class="icon" [ngSwitch]="toastItem.type">
      <svg
        *ngSwitchCase="'success'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.24748 14.4193L4.78003 10.2097L6.15086 8.68751L9.21847 11.5779L14.8244 6L16.2434 7.53846L9.24748 14.4193Z"
        />
      </svg>
      <svg
        *ngSwitchCase="'info'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM8.98397 5.0163V7.05873H11.2308V5.0163H8.98397ZM9.01502 15.0488V8H11.1372V15.0488H9.01502Z"
        />
      </svg>
      <svg
        *ngSwitchCase="'error'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM8.98397 5.0163V7.05873H11.2308V5.0163H8.98397ZM9.01502 15.0488V8H11.1372V15.0488H9.01502Z"
        />
      </svg>
      <svg
        *ngSwitchCase="'warn'"
        width="18"
        height="15"
        viewBox="0 0 18 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.871 13.609L9.56064 0.338213C9.30296 -0.112738 8.65874 -0.112738 8.40105 0.338213L0.090678 13.609C-0.167008 14.06 0.155099 14.6398 0.670472 14.6398H17.3556C17.871 14.5754 18.1931 13.9956 17.871 13.609ZM9.56064 12.385H8.33663V11.161H9.56064V12.385ZM9.56064 9.8726H8.33663V4.71888H9.56064V9.8726Z"
        />
      </svg>
    </div>
    <div class="align-self-center">
      <div class="main-message" [innerHTML]="toastItem.message"></div>
      <div class="sub-message" [innerHTML]="toastItem.subMessage"></div>
      <div class="flex">
        <button
          *ngFor="let button of toastItem.actions"
          (click)="closeAction(button.key)"
          [innerHTML]="button.label"
        >
        </button>
      </div>
    </div>
    <div class="ml-auto pl-3 align-self-start">
      <button
        class="dismiss-button"
        (click)="closeAction(null)"
        *ngIf="toastItem.close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
