import { HttpParams } from '@angular/common/http';

export function getGETParameters(
  paramName?: string
): null | string | { [paramName: string]: string } {
  const params = new HttpParams({
    fromString: window.location.search.replace('?', ''),
  });
  return paramName
    ? params.get(paramName)
    : params.keys().reduce((obj, key) => {
        obj[key] = params.get(key);
        return obj;
      }, {});
}
