import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  ReplaySubject,
  Subscription,
  filter,
  map,
  startWith,
} from 'rxjs';

import { Location } from '@angular/common';
@Component({
  selector: 'ds24-floating-help-button',
  templateUrl: './floating-help-button.component.html',
  styleUrls: ['./floating-help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingHelpButtonComponent implements OnInit, OnDestroy {
  @Output() readonly execute = new EventEmitter<void>();

  shouldShowHelpButton$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private subscription: Subscription;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.subscription = this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(true),
        map(() => !this._router.url.includes('marketplace'))
      )
      .subscribe((shouldShow) => {
        this.shouldShowHelpButton$.next(shouldShow);
      });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
