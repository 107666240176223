import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

export const ASSETS_PATH_ABSOLUTE = '/pgb/orderform-assets/';

@Pipe({
  name: 'orderformAsset',
})
export class AssetPipe implements PipeTransform {
  language: string = this.locale.split('-')[0];

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: string): unknown {
    return ASSETS_PATH_ABSOLUTE + this.language + '/assets/' + value;
  }
}
