import { Injectable, Injector } from '@angular/core';
import { OrderformService } from './orderform.service';
import { lazyLoadComponent } from '../misc/component-loader';
import { OrderformFascade } from '../fascades/orderform.fascade';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { GenericOptionListItem } from '@ds24/ds-generic-api';
import { OrderformIframeService } from './orderform-iframe.service';

function getViewPath() {
  const urlParts = window.location.pathname.split('/');
  return urlParts[2];
}

function getProductsURL(orderformId: number) {
  return `/v2/${getViewPath()}/api/pgb-orderform/${orderformId}/products`;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private _orderformService: OrderformService,
    private _orderformFacadeService: OrderformFascade,
    private _orderformIframe: OrderformIframeService,
    private _httpClient: HttpClient,
    private _injector: Injector
  ) {}

  getOrderformProducts$(
    query: string = '',
    page: number = 1,
    maxItems: number = 100
  ): Observable<GenericOptionListItem[]> {
    return this._orderformFacadeService.config$.pipe(
      switchMap((config) => {
        const URL =
          getProductsURL(config.orderform_id) +
          '?types=vendor&query=' +
          query +
          '&page=' +
          page +
          '&itemsPerPage=' +
          maxItems;
        return this._httpClient.get<GenericOptionListItem[]>(URL);
      })
    );
  }

  preloadComponent(component): Promise<void> {
    return lazyLoadComponent(component, this._injector);
  }

  /**
   * Load only data for specific product and orderform
   * @param productId
   * @param orderformId
   * @param language
   */
  getOrderformJSON(productId: number, orderformId: number, language: string) {
    return firstValueFrom(
      this._orderformService.getOrderformJSON(productId, orderformId, language)
    );
  }

  getProductId() {
    try {
      // @ts-ignore
      return window.order_data.settings.config.product_id;
    } catch (e) {
      return null;
    }
  }

  getProductEditLink(productId: string) {
    return `/${getViewPath()}/account/products/edit/${productId}`;
  }

  getLocaleIso2() {
    return this._orderformService.getLocaleIso2();
  }

  getCustomPayButtonAllowedCountryList() {
    try {
      // @ts-ignore
      return window.order_data.settings.config
        .is_pay_button_label_changed_allowed_countries;
    } catch (e) {
      return [];
    }
  }

  scrollIntoView(element: HTMLElement) {
    if (this._orderformIframe.iframeActive) {
      this._orderformIframe.scrollToIframeElementPosition(
        element.getBoundingClientRect().top
      );
    } else {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  async validateOnlyChildComponents(element: HTMLElement) {
    const validationResult =
      await this._orderformFacadeService.formValidation.emit(
        undefined,
        (el) => {
          return element.contains(el.nativeElement);
        }
      );

    return validationResult.reduce((valid, result) => {
      return valid && result.valid;
    }, true);
  }
}
