import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  NgZone,
} from '@angular/core';
import { debounceTime, filter, take } from 'rxjs/operators';
import {
  isElementInViewport,
  viewportVisibilityChange,
} from '@digistore/ds24-ui/rxjs';
import { lazyLoadComponent } from '../../misc/component-loader';
import { OrderformIframeService } from '../../services/orderform-iframe.service';

@Component({
  selector: 'orderform-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperComponent implements AfterViewInit {
  static definedComponent = {};
  static loadingShift = 0;

  @Input() component: string;
  @Input() visibleForce?: boolean = false;

  constructor(
    private _injector: Injector,
    private _element: ElementRef,
    private _ngZone: NgZone,
    private _orderformIframe: OrderformIframeService
  ) {}

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (
          isElementInViewport(this._element.nativeElement, 300) ||
          this.visibleForce ||
          this._orderformIframe.iframeActive
        ) {
          lazyLoadComponent(this.component, this._injector);
          return;
        }
        viewportVisibilityChange(this._element.nativeElement, 300)
          .pipe(
            debounceTime(10),
            filter((visible) => visible),
            take(1)
          )
          .subscribe(() => {
            lazyLoadComponent(this.component, this._injector);
          });
      }, WrapperComponent.loadingShift++ * 100);
    });
  }
}
