import * as transform from 'lodash/transform';
import * as isEqual from 'lodash/isEqual';
import * as isObject from 'lodash/isObject';

export function objectDifference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? objectDifference(value, base[key])
          : value;
    }
  });
}
