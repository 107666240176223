import * as memoizee from 'memoizee';

/**
 * Use memoize only in standard alone methods
 * * has only variable input parameters
 * * the method uses external parameters which do not changes (constantes)
 @memoize()
 getViewLabel(viewList: string[]) {
    return viewList.map(some => ...)
 }
 */
export function memoize() {
  return function (target: any, key: string, descriptor: PropertyDescriptor) {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
}
