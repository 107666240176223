import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as OrderformActions from './orderform.actions';
import { OrderformService } from '../services/orderform.service';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { OrderformFascade } from '../fascades/orderform.fascade';
import { of } from 'rxjs';
import {
  OrderformOrderState,
  OrderformRedirectResponse,
} from '@orderform/widgets';
import { OrderformIframeService } from '../services/orderform-iframe.service';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from '@digistore/ds24-ui/toaster';
import { MonitoringService } from '../services/monitoring.service';

const checkRequestResult = (
  result: OrderformOrderState | OrderformRedirectResponse,
  successCallback,
  defaultAction
) => {
  if ((result as any).paymentUrl) {
    successCallback();
    return OrderformActions.sendOrderFormRequestPaymentUrlResponse({
      url: (result as any).paymentUrl,
    });
  }
  // Handle redirects from BE
  else if ((result as OrderformRedirectResponse).action === 'redirect') {
    successCallback();
    return OrderformActions.sendOrderFormRequestRedirect({
      data: result as OrderformRedirectResponse,
    });
  } else if ((result as OrderformRedirectResponse).action === 'show_message') {
    return OrderformActions.sendOrderFormResponseShowMessage({
      data: result as OrderformRedirectResponse,
    });
  }

  successCallback();
  return defaultAction;
};

@Injectable()
export class OrderformEffects {
  constructor(
    private _actions$: Actions,
    private _orderformService: OrderformService,
    private _orderformFascade: OrderformFascade,
    private _orderformIframeService: OrderformIframeService,
    private _httpClient: HttpClient,
    private _toasterService: ToasterService,
    private _monitoring: MonitoringService
  ) {}

  onChangeUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        OrderformActions.updateProductQuantity,
        OrderformActions.updateProductETicketId,
        OrderformActions.updateProductVariantId,
        OrderformActions.updateProductCustomForm
      ),
      debounceTime(50),
      withLatestFrom(this._orderformFascade.order$),
      map(([, data]) => {
        return OrderformActions.updateOrderformRequest({ data: data.order });
      })
    )
  );

  /**
   * update
   */
  updateOrderformRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrderformActions.updateOrderformRequest),
      switchMap((action) => {
        return this._orderformService.update$(action.data).pipe(
          map((result: any) => {
            return checkRequestResult(
              result,
              () => {},
              OrderformActions.updateOrderformSuccess({ data: result })
            );
          }),
          catchError((error) => {
            return of(OrderformActions.updateOrderformError(error));
          })
        );
      })
    )
  );

  /**
   * Validate
   */
  validateRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrderformActions.validateOrderformRequest),
      switchMap((action) =>
        this._orderformService.validate$(action.data).pipe(
          map((result) =>
            OrderformActions.validateOrderformRequestSuccess({ data: result })
          ),
          catchError((error) =>
            of(OrderformActions.validateOrderformRequestError(error))
          )
        )
      )
    )
  );

  /**
   * Submit complete form data
   */
  sendOrderFormRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrderformActions.sendOrderFormRequest),
      switchMap((action) =>
        this._orderformService.send$(action.data).pipe(
          map((result: any) => {
            return checkRequestResult(
              result,
              () =>
                this._monitoring.trackUserInteraction(
                  'BuyPaymentRedirect',
                  true
                ),
              OrderformActions.sendOrderFormRequestValidationError({
                data: result as OrderformOrderState,
              })
            );
          }),
          catchError((error) =>
            of(OrderformActions.sendOrderFormRequestError(error))
          )
        )
      )
    )
  );

  redirect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(OrderformActions.sendOrderFormRequestRedirect),
        delay(500),
        tap((action) => {
          if (window !== window.parent) {
            // iframe
            const payload = {
              redirect_id: action.data.redirect_id,
              delay_ms: 0,
            };

            this._orderformIframeService.ds24_sendIframeMsg(
              'redirect',
              payload
            );
          } else {
            window.location.href = action.data.url;
          }
        })
      ),
    { dispatch: false }
  );

  checkDoubleOrderRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrderformActions.checkDoubleOrderRequest),
      withLatestFrom(this._orderformFascade.config$),
      switchMap(([action, config]) => {
        return this._orderformService
          .checkDoubleOrder(config.ajax_key, action.email)
          .pipe(
            map((data) => OrderformActions.checkDoubleOrderResponse({ data }))
          );
      }),
      catchError((error) => {
        return of(OrderformActions.checkDoubleOrderError(error));
      })
    )
  );

  callGenericAjaxUrlRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(OrderformActions.callGenericAjaxUrlRequest),
        switchMap((action) =>
          this._httpClient
            .get<{ debug_info: string; message?: string }>(action.ajaxUrl)
            .pipe(
              filter((result) => !!result.message),
              tap((result) =>
                this._toasterService.success(
                  result.message.replace(/\|/g, '<br>')
                )
              )
            )
        )
      ),
    { dispatch: false }
  );

  showMessage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(OrderformActions.sendOrderFormResponseShowMessage),
        tap((result) => {
          const tmp = document.createElement('DIV');
          tmp.innerHTML = result.data.message;
          this._toasterService.error(tmp.textContent || tmp.innerText || '');
        })
      ),
    { dispatch: false }
  );
}
