import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { InterfaceToastItem } from '../toaster.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'ds24-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit {
  @Input() toastItem?: InterfaceToastItem = {};
  @Output() readonly toastClose = new EventEmitter();

  constructor(private _ngZone: NgZone, @Optional() private _route: Router) {}

  ngOnInit() {
    // Orderform do not have this Router service
    if (this._route) {
      this._route.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          take(1)
        )
        .subscribe((e) => this.closeAction(null));
    }

    if (this.toastItem.duration) {
      this._ngZone.runOutsideAngular(() => {
        setTimeout(() => this.closeAction(null), this.toastItem.duration);
      });
    }
  }

  closeAction(action: string) {
    this.toastClose.emit();
    /* istanbul ignore else */
    if (this.toastItem.afterCloseSubject) {
      this.toastItem.afterCloseSubject.next(action);
      this.toastItem.afterCloseSubject.complete();
    }
  }
}
