import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ElevioService } from './elevio.service';

/**
 * Component to initialize elevio via component
 * e.g used for PGB
 */
@Component({
  selector: 'ds24-elevio',
  templateUrl: './elevio.component.html',
  styleUrls: ['./elevio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElevioComponent implements OnInit {
  @Input() elevioAccountId: string;
  @Input() language: string;
  @Input() brandName: string;
  private _elevioUser: { [key: string]: any };
  @Input() set elevioUser(user: string) {
    this._elevioUser = JSON.parse(user);
  }

  constructor(private elevioService: ElevioService) {}

  ngOnInit(): void {
    // Initialize elevio only on main application (Not in iframes) e.g. PGB-Editor
    if (window === window.parent) {
      this.elevioService.initialize(
        this.elevioAccountId,
        this.language,
        this.brandName,
        this._elevioUser
      );
    }
  }
}
