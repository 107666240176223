import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElevioComponent } from './elevio.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ElevioService } from './elevio.service';
import { FloatingHelpButtonComponent } from './floating-help-button/floating-help-button.component';

@NgModule({
  declarations: [ElevioComponent, FloatingHelpButtonComponent],
  imports: [OverlayModule, CommonModule],
  exports: [ElevioComponent],
})
export class ElevioModule {
  static forRoot(): ModuleWithProviders<ElevioModule> {
    return {
      ngModule: ElevioModule,
      providers: [ElevioService],
    };
  }
}
