import { Component, HostBinding, Input } from '@angular/core';
import { ASSETS_PATH_ABSOLUTE } from '../../pipes/asset.pipe';

@Component({
  selector: 'orderform-payment-progress-indicator',
  templateUrl: './payment-progress-indicator.component.html',
  styleUrls: ['./payment-progress-indicator.component.scss'],
})
export class PaymentProgressIndicatorComponent {
  @HostBinding('class') @Input() state: 'pending' | 'ready' = 'pending';
  @Input() hiddenIndicator = false;
  staticBrand = (window as any).SITEOWNER_BRAND;
  logoUrl = `${ASSETS_PATH_ABSOLUTE}de/assets/brand/${this.staticBrand}/logo/logo_inverse.svg`;
}
