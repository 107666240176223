import { Unsubscribable } from 'rxjs';

export class AdvancedEventEmitter<T, R, C = void> {
  private _listenerCount = 0;
  private _listener: {
    [id: number]: {
      callback: (resultListBefore?: R[], value?: T) => Promise<R>;
      context: C;
    };
  } = {};

  async emit(value?: T, contextFilter?: (context: C) => boolean): Promise<R[]> {
    /**
     * ruft sequenziell alle listener auf und
     * teil d
     */
    let listenerIdsToCall = Object.keys(this._listener);
    if (contextFilter) {
      listenerIdsToCall = listenerIdsToCall.filter((id) =>
        contextFilter(this._listener[id].context)
      );
    }
    return await listenerIdsToCall
      .sort()
      .reduce<Promise<R[]>>(async (result, listenerId) => {
        const arr = await result;
        arr.push(await this._listener[listenerId].callback(arr, value));
        return arr;
      }, Promise.resolve([]));
  }

  subscribe(
    callback: (resultListBefore?: R[], value?: T) => Promise<R>,
    context: C
  ): Unsubscribable {
    const id = this._listenerCount++;
    this._listener[id] = { callback, context };
    return {
      unsubscribe: ((listenerId) => {
        return () => delete this._listener[listenerId];
      })(id),
    };
  }
}
