import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERFORM_FEATURE_KEY } from './orderform.reducer';
import {
  OrderformOrderState,
  OrderformSettingsState,
  OrderformState,
} from './orderform.models';

// Lookup the 'Orderform' feature state managed by NgRx
export const getOrderformState = createFeatureSelector<OrderformState>(
  ORDERFORM_FEATURE_KEY
);

export const getOrderformPending = createSelector(
  getOrderformState,
  (state: OrderformState) => state.pending
);

export const getOrderformSubmitState = createSelector(
  getOrderformState,
  (state: OrderformState) => state.submit
);
export const getOrderPaymentUrl = createSelector(
  getOrderformState,
  (state: OrderformState) => state.paymentUrl
);

export const getOrderformSettings = createSelector(
  getOrderformState,
  (state: OrderformState) => state.order.settings
);

export const getOrderformProductList = createSelector(
  getOrderformSettings,
  (settings: OrderformSettingsState) => settings.product.items
);

export const getOrderformProductSettings = createSelector(
  getOrderformSettings,
  (settings: OrderformSettingsState) => settings.product.settings
);

export const getOrderformGlobalSettings = createSelector(
  getOrderformSettings,
  (settings: OrderformSettingsState) => settings.global
);

export const getOrder = createSelector(
  getOrderformState,
  (state: OrderformState) => state.order
);

export const getOrderformValidation = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.validation
);

export const getOrderformMessages = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.messages
);

export const getOrderItems = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.order.items
);

export const getOrderPaymentPlanId = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.order.payment_plan_id
);

export const getOrderPreSelectedPaymentPlanId = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.order.preselected_payment_plan_id
);

export const getOrderSelectedPaymentPlan = createSelector(
  getOrder,
  (order: OrderformOrderState) => order.order.selected_plan
);

export const getOrderformPaymentPlanList = createSelector(
  getOrderformState,
  (state: OrderformState) => state.order.payment_plans
);

export const getOrderformConfig = createSelector(
  getOrderformSettings,
  (state: OrderformSettingsState) => state.config
);

export const getOrderformExitPopup = createSelector(
  getOrderformSettings,
  (state: OrderformSettingsState) => state.exit_popup
);
