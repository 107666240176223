import { HostListener, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApmService } from '@elastic/apm-rum-angular';
import { Transaction } from '@elastic/apm-rum';
import { firstValueFrom, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

const MONITORING_ERROR_INCLUDE_LIST = [
  'unzer',
  'digistore24',
  'svencart',
  'stripe',
];
const MONITORING_ERROR_EXCLUDE_LIST = [
  'https://',
  'safari-extension',
  'chrome-extension',
];

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private _trackValidationErrorQueue = [];
  constructor(private _router: Router, private _elastic: ApmService) {}

  async initialize() {
    // @ts-ignore
    const ELASTIC_CONFIG = window.ELASTIC_CONFIG;

    if (
      ELASTIC_CONFIG &&
      ELASTIC_CONFIG.elasticApmServiceName !== '' &&
      ELASTIC_CONFIG.elasticApmServiceName.slice(0, 1) !== '$'
    ) {
      this._elastic.init({
        serviceName: ELASTIC_CONFIG.elasticApmServiceName,
        serverUrl: ELASTIC_CONFIG.elasticApmServerUrl,
        serviceVersion: ELASTIC_CONFIG.elasticApmServiceVersion,
        environment: ELASTIC_CONFIG.elasticApmEnvironment,
        disableInstrumentations: ['click'],
        flushInterval: 200,
      });
      this._elastic.apm.addLabels({
        'user_agent.device.width': window.innerWidth,
      });
      if (window.location.pathname.startsWith('/product')) {
        this._elastic.apm.addLabels({ orderform: 'PGB' });
        this._elastic.apm.addLabels({ hasParentFrame: !!window.parent });
      } else {
        this._elastic.apm.addLabels({ renew: 'PGB' });
      }

      this._elastic.apm.addFilter(function (payload) {
        if (payload.errors) {
          payload.errors = payload.errors.filter(function (error) {
            if (
              MONITORING_ERROR_INCLUDE_LIST.filter(
                (subject) => error.culprit.indexOf(subject) > -1
              ).length > 0
            ) {
              return true;
            }
            if (
              MONITORING_ERROR_EXCLUDE_LIST.filter(
                (subject) => error.culprit.indexOf(subject) > -1
              ).length > 0
            ) {
              return false;
            }
            return true;
          });
        }
        return payload;
      });
    }
  }

  public addLabels(labels: Labels) {
    this._elastic.apm.addLabels(labels);
  }

  public trackUserInteraction(event: string, autoEnd: boolean): Transaction {
    const interaction = this._elastic.apm.startTransaction(
      event,
      'user-interaction',
      {
        startTime: performance.now() - 10,
      }
    );
    if (this._trackValidationErrorQueue.length > 0) {
      this._trackValidationErrorQueue.forEach((item) => {
        this.trackValidationError(item.name, item.source, item.component);
      });
      this._trackValidationErrorQueue = [];
    }
    if (autoEnd) {
      interaction.end();
    }
    return interaction;
  }

  public trackValidationError(
    name: string,
    source: 'backend' | 'frontend',
    component: string
  ) {
    const transaction = this._elastic.apm.getCurrentTransaction();
    if (!this.isActive()) {
      return;
    }
    if (transaction) {
      transaction
        .startSpan(name, 'orderform-validation.' + source + '.' + component, {
          startTime: performance.now() - 10,
        })
        .end();
    } else {
      this._trackValidationErrorQueue.push({ name, source, component });
    }
  }

  public isActive() {
    return this._elastic?.apm.isActive();
  }
}
