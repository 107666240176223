import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorsComponent } from './input-errors/input-errors.component';

@NgModule({
  declarations: [InputErrorsComponent],
  imports: [CommonModule],
  exports: [InputErrorsComponent],
})
export class FormUtilitiesModule {}
